import React from 'react'
import { Link } from 'react-router-dom'
const MaintenancePage = (aParams) => {

    const { Icon, Heading, Content } = aParams.aParams;

    return (
        <>
            <div className="min-h-screen flex flex-col justify-center items-center">
                <img src={Icon} alt="Logo" className={`w-32 h-32 bg-[#893e1b] rounded-lg flex items-center justify-center p-2 mb-5`}/>
                    <h1 className="text-4xl font-bold mb-4">{Heading}</h1>
                    <p className="text-lg mb-8 px-4 md:px-0 text-center">{Content}</p>
                    <div className="flex justify-center items-center space-x-4">
                        <Link to={"/"} className={`bg-[#893e1b] hover:bg-[#893e1b]/[0.9] text-white px-4 py-2 rounded-md`}>Powrót</Link>
                    </div>
            </div>
        </>
    )
}

export default MaintenancePage