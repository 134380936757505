import React from 'react'
import { useRoutes } from 'react-router-dom'

import GrupaSmart from './pages/grupa-smart/GrupaSmart'
import SmartInvest from './pages/smart-invest/SmartInvest'
import SmartBuild from './pages/smart-build/SmartBuild'
import SmartGarden from './pages/smart-garden/SmartGarden'
import NoPage from './pages/default/NoPage'

const App = () => {

  const routes = useRoutes([
    { path: '/', element: <GrupaSmart /> },
    { path: 'smartinvest', element: <SmartInvest /> },
    { path: 'smartbuild', element: <SmartBuild /> },
    { path: 'smartgarden', element: <SmartGarden /> },
    { path: '*', element: <NoPage /> },
  ]);

  return routes;

}

export default App