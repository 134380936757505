import React from 'react'

const Feature = () => {
    return (
        <>
            <section className="text-gray-400 bg-stone-900 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="p-4 w-full">
                            <div className="h-full bg-stone-800 bg-opacity-40 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center">
                                <h2 className="tracking-widest text-xs title-font font-medium mb-1 logo">SMART <span className='text-[#97B738]'>INVEST</span></h2>
                                <h1 className="title-font sm:text-2xl text-xl font-medium text-white mb-3">Dlaczego właśnie my?</h1>
                                <p className="leading-relaxed mb-3">Wyróżnia nas - Przede wszystkim zaangażowanie oraz lojalność. Te dwie cechy stawiamy na podium.
                                    Poważnie podchodzimy do współpracy zarówno z klientami jak i naszymi inwestorami. Dbamy o partnerskie relacje z wieloma osobami z branży, dzięki czemu posiłkujemy się wzajemną pomocą.
                                    Nasz zespół współpracuje z architektami, pośrednikami, prawnikami, notariuszami, ekipami remontowo – budowlanymi oraz homestagingowymi, którzy od lat nas nie zawiedli. Dzięki nim cały proces począwszy od znalezienia okazji, poprzez remont i homestaging, kończąc na rentownej sprzedaży przebiega sprawnie i terminowo. Dlatego gwarantujemy regularny zysk i planowo wypłacany zysk/odsetki. Nieskromnie ośmielamy się twierdzić, iż jesteśmy zespołem szczerze lubianym w naszej branży, dlatego też nierzadko mamy dostęp do okazyjnych cen i perełek inwestycyjnych. Ręczymy także jakością wykończenia naszych lokali co gwarantuje możliwość wyjątkowo szybkiej sprzedaży z bardzo dobrym zyskiem.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Feature