import React from 'react'
import { Link } from 'react-router-dom'
import GMSBlue from '../../../assets/images/GrupaMusialSmart-Dark-Blue.png'
import Config from '../../../Config'

const Footer = () => {
    return (
        <div id="contact">
            <footer className="text-gray-600 body-font border-t">
                <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <Link to={"#"} className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                            <div className="w-10 h-10 text-white p-2 bg-[#3c6ba8] rounded-full" viewBox="0 0 24 24">
                                <svg width="24" height="24" viewBox="0 0 179 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="3.53553" y="90.2843" width="35" height="35" transform="rotate(-45 3.53553 90.2843)" stroke="white" strokeWidth="5" />
                                    <rect x="34.5355" y="59.2843" width="35" height="35" transform="rotate(-45 34.5355 59.2843)" stroke="white" strokeWidth="5" />
                                    <rect x="64.5355" y="89.2843" width="35" height="35" transform="rotate(-45 64.5355 89.2843)" stroke="white" strokeWidth="5" />
                                    <rect x="94.5355" y="59.2843" width="35" height="35" transform="rotate(-45 94.5355 59.2843)" stroke="white" strokeWidth="5" />
                                    <rect x="125.536" y="28.2843" width="35" height="35" transform="rotate(-45 125.536 28.2843)" stroke="white" strokeWidth="5" />
                                </svg>
                            </div>
                            <span className="ml-3 text-xl logo">SMART <span className='text-[#3c6ba8]'>BUILD</span></span>
                        </Link>
                        <p className="mt-2 text-sm text-gray-500">Wykończenia i adaptacje</p>
                    </div>
                    <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">GRUPA SMART</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">GRUPA SMART Norbert Musiał</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">Złota 373, 32-859 Złota</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">NIP 8691998866</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">REGON 386798639</Link>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">SMART INVEST</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">SMART INVEST MUSIAŁ Sp. z o.o.</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">ul. Bieżanowska 77, 30-826 Kraków</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">NIP 6793261269</Link>
                                </li>
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">KRS 0001022157</Link>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">KONTAKT</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to={"tel:572996388"} className="text-gray-600 hover:text-gray-800">Tel: 572 996 388</Link>
                                </li>
                                <li>
                                    <Link to={"mailto:smart@build-krakow.com"} className="text-gray-600 hover:text-gray-800">Email: smart@build-krakow.com</Link>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">BIURO</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to={"#"} className="text-gray-600 hover:text-gray-800">ul. Czyżówka 16 <br/>30-526 Kraków</Link>
                                </li>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className='container mx-auto p-5 flex justify-end'>
                    <img src={GMSBlue} alt="Grupa Smart Musiał" className='w-28'/>
                </div>
                <div className="bg-gray-100">
                    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                        <p className="text-gray-500 text-sm text-center sm:text-left">© 2023 Smart Build —
                            <Link to={"#"} rel="noopener noreferrer" className="text-gray-600 ml-1">Smart-Krakow.pl</Link>
                        </p>
                        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                            <Link to={Config.socialMedia.facebook} target="_blank" className="text-gray-500">
                                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                </svg>
                            </Link>
                            <Link to={Config.socialMedia.instagram} target='_blank' className="ml-3 text-gray-500">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                </svg>
                            </Link>
                        </span>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer