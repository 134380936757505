import React, { useEffect } from 'react'

import AOS from "aos";
import "aos/dist/aos.css";

import "./style/ScrollBar.css"

import Navigation from './components/Navigation'
import Hero from './components/Hero'
import AboutUs from './components/AboutUs'
import CtaSimple from './components/CtaSimple'
import Feature from './components/Feature'
import Offert from './components/Offert'
import WorkWithUs from './components/WorkWithUs'
import Footer from './components/Footer'

const SmartInvest = () => {

    useEffect(() => {

        document.title = "Smart Invest - Inwestycje nieruchomościami bez wysiłku dla pasywnych inwestorów."

        AOS.init();
        AOS.refresh();
        
      }, []);

    return (
        <>
            <Navigation />
            <Hero />
            <AboutUs />
            <CtaSimple />
            <WorkWithUs />
            <Offert />
            <Feature />
            <Footer />
        </>
    )
}

export default SmartInvest