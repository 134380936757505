import React from 'react'
import '../style/Hero.css'
import String from '../../../locales/pl-PL/translations'
const Hero = () => {
    return (
        <>
            <section className="hero-image-background text-gray-400 bg-stone-900 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    {/* <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" /> */}
                    <div className="text-center lg:w-2/3 w-full">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">{String.Hero.heading}</h1>
                        <p className="leading-relaxed mb-8 text-white">{String.Hero.span}</p>
                        <div className="flex justify-center">
                            {/* <button className="inline-flex text-white border border-gray-400 py-2 rounded-full text-lg">
                                <LiaSortDownSolid />
                            </button> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero