import React from 'react'
import MaintenancePage from '../../components/maintenancePage/MaintenancePage'

import SmartGardenIcon from '../../assets/images/SmartGardenSignature.svg'

const SmartGarden = () => {
  return (
    <>
      <MaintenancePage
        aParams={{
          Icon: SmartGardenIcon,
          Heading: "Strona w budowie",
          Content: "Obecnie trwają prace nad stroną Smart Garden.",
          MainColor: "#893e1b"
        }}
      />
    </>
  )
}

export default SmartGarden