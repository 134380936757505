import React from 'react'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import '../style/Partners.css'

import partnerSmartInvest from "../../../assets/images/partners/smartinvest.png"
import partnerSmartGarden from "../../../assets/images/partners/smartgarden.png"
import partnerRigips from "../../../assets/images/partners/pigips.png"
import partnerAttic from "../../../assets/images/partners/attic.png"
import partnerGraco from "../../../assets/images/partners/graco.png"
import partnerFestool from "../../../assets/images/partners/festool.png"
import partnerFlugger from "../../../assets/images/partners/flugger.png"
import partnerKnauf from "../../../assets/images/partners/knauf.png"
import partnerMapei from "../../../assets/images/partners/mapei.png"
import partnerSemin from "../../../assets/images/partners/semin.png"
import partnerBudmat from "../../../assets/images/partners/budmat.png"
import partnerSiniat from "../../../assets/images/partners/Siniat.png"


const Partners = () => {

    const partners = [
        {
            image: partnerSmartInvest
        },
        {
            image: partnerSmartGarden
        },
        {
            image: partnerBudmat
        },
        {
            image: partnerSiniat
        },
        {
            image: partnerRigips
        },
        {
            image: partnerAttic
        },
        {
            image: partnerGraco
        },
        {
            image: partnerFestool
        },
        {
            image: partnerFlugger
        },
        {
            image: partnerKnauf
        },
        {
            image: partnerMapei
        },
        {
            image: partnerSemin
        }
    ]

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 7,
        responsive: [{
            breakpoint: 640,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                initialSlide: 5
            }
        }]
    };

    return (
        <div id="partners">
            <section className="text-gray-600 body-font max-w-[1280px] mx-auto">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-24">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Partnerzy</h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Wspólnie z nimi tworzymy innowacyjne projekty i osiągamy wyjątkowe rezultaty. Dzięki naszym partnerom możemy śmiało podejmować się najbardziej wymagających wyzwań. Ich wsparcie i zaufanie są fundamentem naszych sukcesów. Razem budujemy lepszą przyszłość dla Twoich przestrzeni.</p>
                    </div>
                    <Slider {...settings} className="flex flex-wrap -m-4 overflow-hidden">
                        {partners.map((val,key) => {
                            return (
                                <div className="p-4" key={key}>
                                    <div className="flex relative">
                                        <img alt="gallery" className="w-full h-full object-cover object-center" src={val.image} />
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Partners