import React, { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link';
import String from '../../../locales/pl-PL/translations';
import '../style/Navigation.css'

const Navigation = () => {

    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 90) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <header className={`${scrolling ? "bg-stone-900/30 backdrop-blur-md" : ""} text-gray-400 bg-stone-900 body-font sticky top-0 z-40`}>
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <HashLink smooth to={"#"} className="flex title-font font-medium items-center text-white md:mb-0">
                        <div className="w-15 h-15 text-white p-2 bg-[#97B738] rounded-full" viewBox="0 0 32 32">
                            <svg width="32" height="32" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 106.956V4H55.2315V106.956" stroke="white" strokeWidth="7" />
                                <path d="M4 54.2463H55.2315" stroke="white" strokeWidth="7" />
                                <path d="M4 28.6306H55.2315" stroke="white" strokeWidth="7" />
                                <path d="M18.7783 106.463V79.3694H39.9606V106.463" stroke="white" strokeWidth="7" />
                                <path d="M61.6355 56.2167L77.8916 38.9753L104 63.6059V107.448" stroke="white" strokeWidth="7" />
                                <path d="M69.0246 107.448V79.3694H89.7143V107.448" stroke="white" strokeWidth="7" />
                            </svg>
                        </div>
                        <span className="ml-3 text-xl lg:text-4xl logo">SMART <span className='text-[#97B738]'>INVEST</span></span>
                    </HashLink>
                    <nav className="hidden md:block md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
                        <HashLink smooth to={"#aboutus"} className="uppercase mr-5 hover:text-white">{String.Navigation.aboutUs}</HashLink>
                        <HashLink smooth to={"#invest"} className="uppercase mr-5 hover:text-white">{String.Navigation.invest}</HashLink>
                        <HashLink smooth to={"#offert"} className="uppercase mr-5 hover:text-white">{String.Navigation.offert}</HashLink>
                    </nav>
                    <HashLink smooth to={"#contact"}>
                    <button className="hidden md:inline-flex items-center bg-stone-800 border-0 py-1 px-3 focus:outline-none hover:bg-stone-700 rounded text-base mt-4 md:mt-0">Kontakt
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </button>
                    </HashLink>
                </div>
            </header>
        </>
    )
}

export default Navigation