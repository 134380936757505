import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import bgVideo from '../../assets/videos/office-video.mp4'
import smartInvestSignature from '../../assets/images/SmartInvestSignatureThin.svg'
import smartBuildSignature from '../../assets/images/SmartBuildSignature.svg'
import smartGardenSignature from '../../assets/images/SmartGardenSignature.svg'
import GMSGreen from '../../assets/images/GrupaMusialSmart-Green.png'
import GMSBlue from '../../assets/images/GrupaMusialSmart-Blue.png'
import GMSBrown from '../../assets/images/GrupaMusialSmart-Brown.png'
import HeroThumbnail from '../../assets/images/Hero-thumbnail.webp'
import './style/style.css'
import { isIOS } from 'react-device-detect'

const GrupaSmart = () => {

  useEffect(() => {
    document.title = "Smart-Krakow.pl - Inwestycje Nieruchomości Ogrody. Smart Invest Smart Build Smart Garden."
  })

  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.7;
    videoRef.current.play();
  };

  return (
    <>
      {!isIOS ? <video
        poster={HeroThumbnail}
        lazyloading="true"
        ref={videoRef}
        onCanPlay={() => setPlayBack()}
        className='videoTag'
        loop={true}
        controls={false}
        playsInline
        muted
      >
        <source src={bgVideo} type='video/mp4' />
      </video>
        :
        <div className='videoTag--static' />
      }

      <section
        style={{
          position: "relative",
          zIndex: "1"
        }}
        className="text-gray-400 bg-black bg-opacity-75 body-font">
        <div className="mx-auto">
          <nav className="lg:flex flex-wrap">
            <section className="relative h-[50svh] lg:h-screen hover:backdrop-blur-sm lg:w-1/2 lg:mb-0 hover:bg-opacity-75 ease-in duration-300 hover:bg-gradient-to-t hover:from-lime-900 bg-gradient-to-t from-lime-900/[0.3]">
              <Link to={"smartinvest"}>
                <div className="h-[50svh] lg:h-screen flex flex-row lg:flex-col justify-center items-center lg:h-screen text-center p-8 2xl:p-32">
                  <div className='min-w-[10vh] min-h-[10vh] h-[10vh] lg:w-32 lg:min-h-32 lg:h-32 bg-[#97B738]/[.5] rounded-lg flex items-center justify-center p-2'>
                    <img src={smartInvestSignature} alt="Smart Invest" className='h-[6vh] lg:h-full' />
                  </div>
                  <div className='ml-5 lg:ml-0'>
                    <span className="none lg:inline-block h-1 w-10 rounded bg-[#97B738] mt-6 mb-4"></span>
                    <h2 className="text-white font-medium title-font tracking-wider text-xl logo">SMART <span className='text-[#97B738]'>INVEST</span></h2>
                    <p className="text-gray-300">Inwestycje w nieruchomości</p>
                    <p className="hidden-text mt-10 leading-relaxed text-gray-300">Jesteśmy grupą pasjonatów oferujących kompleksowe inwestycje nieruchomościowe. Specjalizujemy się w wyszukiwaniu okazji inwestycyjnych, nadając im wartość przez profesjonalne wykończenie i zyskową sprzedaż. Współpracujemy z inwestorami pasywnymi, oferując bezwysiłkowe inwestowanie. Skontaktuj się z nami, aby pomnożyć swoje oszczędności bez ryzyka.</p>
                  </div>
                </div>
              </Link>
              <img src={GMSGreen} alt="GMSGreen" className='w-[20vw] md:w-[10vw] lg:w-[7vw] absolute bottom-5	right-5' />
            </section>
            <section className="relative h-[50svh] lg:h-screen hover:backdrop-blur-sm lg:w-1/2 lg:mb-0 hover:bg-opacity-75 ease-in duration-300 hover:bg-gradient-to-t hover:from-sky-900 md:border-x border-neutral-700 bg-gradient-to-t from-sky-900/[0.3]">
              <Link to={"smartbuild"}>
                <div className="h-[50svh] lg:h-screen flex flex-row lg:flex-col justify-center items-center lg:h-screen text-center p-8 2xl:p-32">
                  <div className='min-w-[10vh] min-h-[10vh] h-[10vh] lg:w-32 lg:min-h-32 lg:h-32 bg-blue-500/[.5] rounded-lg flex items-center justify-center p-2'>
                    <img src={smartBuildSignature} alt="smartBuildSignature" className='h-[5vh] lg:h-full' />
                  </div>
                  <div className='ml-5 lg:ml-0'>
                    <span className="none lg:inline-block h-1 w-10 rounded bg-blue-500 mt-6 mb-4"></span>
                    <h2 className="text-white font-medium title-font tracking-wider text-xl logo">SMART <span className='text-blue-500'>BUILD</span></h2>
                    <p className="text-gray-300">Wykończenia i adaptacje</p>
                    <p className="hidden-text mt-10 leading-relaxed text-gray-300">Nasza firma to ekspert w remontach, wykończeniach i kompleksowych projektach. Długoletnie doświadczenie w branży budowlanej gwarantuje bezbolesny proces realizacji. Pomagamy w projektowaniu, doradzamy w trendach i materiałach. Nasza specjalistyczna ekipa zapewnia jakość, terminowość i bliską relację z klientem. Twój sukces to nasze uznanie.</p>
                  </div>
                </div>
              </Link>
              <img src={GMSBlue} alt="GMSBlue" className='w-[20vw] md:w-[10vw] lg:w-[7vw] absolute bottom-5	right-5' />
            </section>
            {/* <section className="relative h-[33.3333svh] lg:h-screen hover:backdrop-blur-sm lg:w-1/3 lg:mb-0 hover:bg-opacity-75 ease-in duration-300 hover:bg-gradient-to-t hover:from-yellow-900 bg-gradient-to-t from-yellow-900/[0.3]">
              <Link to={"smartgarden"}>
                <div className="h-[33.3333svh] lg:h-screen flex flex flex-row lg:flex-col justify-center items-center lg:h-screen text-center p-8 2xl:p-32">
                  <div className='min-w-[10vh] min-h-[10vh] h-[10vh] lg:w-32 lg:min-h-32 lg:h-32 bg-yellow-800/[.5] rounded-lg flex items-center justify-center p-2'>
                    <img src={smartGardenSignature} alt="" className='h-[6vh] lg:h-full' />
                  </div>
                  <div className='ml-5 lg:ml-0'>
                    <span className="none lg:inline-block h-1 w-10 rounded bg-yellow-800 mt-6 mb-4"></span>
                    <h2 className="text-white font-medium title-font tracking-wider text-xl logo">SMART <span className='text-yellow-800'>GARDEN</span></h2>
                    <p className="text-gray-300">Aranżacje przestrzeni ogrodowych</p>
                    <p className="hidden-text mt-10 leading-relaxed text-gray-300">Firma specjalizuje się w kompleksowych projektach ogrodowo-tarasowych, obejmując prywatne i usługowe przestrzenie zielone oraz systemy oświetleniowe i nawadniające. Oferujemy fachowe doradztwo w kwestii estetyki, kolorystyki i modernizacji terenu, zapewniając zarówno funkcjonalność, jak i idealne miejsce wypoczynku.</p>
                  </div>
                </div>
              </Link>
              <img src={GMSBrown} alt="GMSBrown" className='w-[20vw] md:w-[10vw] lg:w-[7vw] absolute bottom-5	right-5' />
            </section> */}
          </nav>
        </div>
      </section>
      </>
  )
}

export default GrupaSmart