import React from 'react'
import { Link } from 'react-router-dom';

import paintBrush from '../../../assets/images/paint-brush.svg'
import drywall from '../../../assets/images/drywall.svg'

const Offert = () => {

    return (
        <div id="offert">

            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Oferta</h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Przekształć swoje pomysły w rzeczywistość! Oferujemy kompleksowe usługi remontowe i wykończeniowe, które przekroczą Twoje oczekiwania. Projektujemy, doradzamy i realizujemy pod klucz. Nasza pasja do doskonałości sprawi, że Twoje pomieszczenia staną się idealne</p>
                    </div>
                    <div className="flex flex-wrap -m-4 max-w-[1024px] mx-auto p-0 lg:p-12">
                        <div className="p-4 lg:w-1/2">
                            <div className="h-full">
                                <div className="flex items-center justify-center">
                                    <div className="flex items-center justify-center text-white w-24 h-24 bg-[#3c6ba8] rounded-md mb-8">
                                        <img src={paintBrush} width={62} alt=""/>
                                    </div>
                                </div>
                                <div className="flex-grow sm:pl-8">
                                    <h2 className="title-font font-medium text-lg text-gray-900">Usługi remontowe i wykończeniowe</h2>
                                    <ul className="mb-4">
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Kompleksowe wykończenie wnętrz pod klucz</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Malowanie natryskowe</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Szpachlowanie natryskowe</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Usługi glazurnicze oraz montaż płytek wielkoformatowych</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Usługi monterskie, biały montaż</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Usługi murarskie</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Usługi parkieciarskie</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Usługi montażu wykładzin podłogowych</li>
                                    </ul>
                                    <h2 className="title-font font-medium text-lg text-gray-900">Usługi instalacyjne</h2>
                                    <ul className="mb-4">
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Instalacje wod-kan oraz klimatyzacja i wentylacja (klim-went)</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Instalacje elektryczne</li>
                                    </ul>
                                    <h2 className="title-font font-medium text-lg text-gray-900">Usługi inwestycyjne</h2>
                                    <ul>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Kompleksowa obsługa inwestycji</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/2 border-0 lg:border-l">
                            <div className="h-full">
                                <div className="flex items-center justify-center">
                                    <div className="flex items-center justify-center text-white  w-24 h-24 bg-[#3c6ba8] rounded-md mb-8">
                                        <img src={drywall} width={62} alt=""/>
                                    </div>
                                </div>
                                <div className="flex-grow sm:pl-8">
                                    <h2 className="title-font font-medium text-lg text-gray-900">Montaż Konstrukcji Poddasza</h2>
                                    <ul className="mb-4">
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Montaż zabudowy poddasza</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Montaż sufitów podwieszanych</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Montaż sufitów kasetonowych, rastrowych oraz panelowych</li>
                                    </ul>
                                    <h2 className="title-font font-medium text-lg text-gray-900">Montaż Ścian i Zabudów</h2>
                                    <ul>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Montaż ścian działowych</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Montaż zabudów szachtów instalacyjnych, windowych</li>
                                        <li className="before:content-['-'] before:absolute before:-left-4 relative ml-4">Montaż innych typowych oraz niestandardowych elementów wykończenia budynków</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-neutral-50">
                    <div className="container max-w-[1024px] px-5 py-24 mx-auto">
                        <p className='text-center'>Gotów zmienić swoje przestrzenie? Nie czekaj! <span className='font-bold text-[#3c6ba8]'>Zadzwoń już teraz</span>, a my zrealizujemy Twoje marzenia o idealnym domu lub biurze. Odkryj, jak możemy uczynić Twoje pomieszczenia wyjątkowymi. Czekamy na Twój telefon - zamień swoje marzenia w rzeczywistość już dziś!</p>
                        <button className="flex mx-auto mt-16 text-white bg-[#3c6ba8] border-0 py-2 px-8 focus:outline-none hover:bg-[#3c6ba8] rounded text-lg"><Link to={"tel: 572 996 388"}>(+48) 572 996 388</Link></button>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Offert