import React from 'react'
import { Link } from 'react-router-dom'
const NoPage = () => {
    return (
        <>
            <section className="bg-white dark:bg-neutral-800 ">
                <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
                    <div>
                        <p className="text-sm font-medium text-neutral-500 dark:text-neutral-400">404 error</p>
                        <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">Nie znaleziono strony</h1>
                        <p className="mt-4 text-gray-500 dark:text-gray-400">Przepraszamy, szukana strona nie istnieje.</p>

                        <div className="flex items-center mt-6 gap-x-3">
                            <Link to={"/"}>
                                <button className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-neutral-500 rounded-lg shrink-0 sm:w-auto hover:bg-neutral-600 dark:hover:bg-neutral-500 dark:bg-neutral-600">
                                    Strona główna
                                </button>
                            </Link>
                        </div>

                        <div className="mt-10 space-y-6">
                            <div>
                                <Link to={"/"} className="inline-flex items-center text-sm text-neutral-500 gap-x-2 dark:text-neutral-400 hover:underline">
                                    <span  className='text-lg text-white'>Smart <span className='text-[#97b738]'>Invest</span></span>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg>
                                </Link>

                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Inwestycje w nieruchomości</p>
                            </div>

                            {/* <div>
                                <Link to={"smartbuild"} className="inline-flex items-center text-sm text-neutral-500 gap-x-2 dark:text-neutral-400 hover:underline">
                                    <span  className='text-lg text-white'>Smart <span className='text-blue-500'>Build</span></span>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg>
                                </Link>

                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Wykończenia i adaptacje.</p>
                            </div>

                            <div>
                                <Link to={"smartgarden"} className="inline-flex items-center text-sm text-neutral-500 gap-x-2 dark:text-neutral-400 hover:underline">
                                    <span className='text-lg text-white'>Smart <span className='text-yellow-800'>Garden</span></span>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                    </svg>
                                </Link>

                                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Aranżacje przestrzeni ogrodowych</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NoPage