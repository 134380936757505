import React from 'react'

import image1 from '../../../assets/images/web_img_1.png'
import image2 from '../../../assets/images/web_img_2.png'
import image3 from '../../../assets/images/web_img_3.png'
import image4 from '../../../assets/images/web_img_4.png'

const AboutUs = () => {
    return (
        <div id="aboutUs">

            {/* ----------------------------- */}

            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mt-16 md:mt-0 order-2 md:order-1 md:mb-0 items-center text-center"  >
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" data-aos="fade-up" data-aos-offset="250">W czym się specjalizujemy?</h1>
                        <p className="mb-8 leading-relaxed" data-aos="fade-up" data-aos-offset="250"><span className='logo'>SMART <span className='text-[#3c6ba8]'>BUILD</span></span> to firma specjalizująca się w kompleksowych wykończeniach i remontach wnętrz: w domach, mieszkaniach oraz lokalach biurowych. Jesteśmy młodą i prężnie rozwijącą się firmą, a przede wszystkim podchodząca do każdego tematu i zlecenia profesjonalnie. A poprzez nasze zaangażowanie, praktykę i ciągłe zgłębianie wiedzy technicznej oraz podnoszenie kwalifikacji, budujemy markę godną tego tytułu.</p>
                    </div>
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 order-1 md:order-2">
                        <img className="object-cover object-center rounded" alt="hero" width={720} src={image2} />
                    </div>
                </div>
            </section>

            {/* ----------------------------- */}


            <section className="bg-neutral-50 text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <img className="object-cover object-center rounded" alt="hero" width={720} src={image3} />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center"  >
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" data-aos="fade-up" data-aos-offset="250">Jak pracujemy?</h1>
                        <p className="mb-8 leading-relaxed" data-aos="fade-up" data-aos-offset="250">Działamy na terenie Krakowa i okolic, naprzeciw naszym klientom wychodzimy z kompleksową usługą realizacji inwestycji. Współpracujemy zarówno z osobami prywatnymi, jak i również firmami oraz instytucjami.</p>
                    </div>
                </div>
            </section>

            {/* ----------------------------- */}

            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mt-16 md:mt-0 order-2 md:order-1 md:mb-0 items-center text-center"  >
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" data-aos="fade-up" data-aos-offset="250">Nasze standardy</h1>
                        <p className="mb-8 leading-relaxed" data-aos="fade-up" data-aos-offset="250">Przy wykonywaniu prac zachowujemy jakość, pod która się podpisujemy. Wybierając naszą firmę nie martwisz się o skoordynowanie pracy wielu ekip, ponieważ wszystko wykonujemy od początku do końca. Zadzwoń a sam się przekonasz, że podejdziemy do Ciebie indywidualnie, oraz weźmiemy pod uwagę wszystkie twoje potrzeby i pomysły</p>
                    </div>
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 order-1 md:order-2">
                        <img className="object-cover object-center rounded" alt="hero" width={720} src={image4} />
                    </div>
                </div>
            </section>

            {/* ----------------------------- */}

            <section className="bg-neutral-50 text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <img className="object-cover object-center rounded" alt="hero" width={720} src={image1} />
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center"  >
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900" data-aos="fade-up" data-aos-offset="250">Realizacja</h1>
                        <p className="mb-8 leading-relaxed" data-aos="fade-up" data-aos-offset="250">Do każdego zlecenia podchodzimy indywidualnie, w zależności od zakresu i stopnia skomplikowania prac. Na podstawie dotychczas zrealizowanych prac możemy zastosować ceny za m2 obiektu aby uzyskać orientacyjną wycenę. Jednak ostateczna cena jest ustalana u Ciebie Drogi Kliencie!</p>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default AboutUs