import React from 'react'
import String from '../../../locales/pl-PL/translations'
import Image03 from '../../../assets/images/image11.png'

const AboutUs = () => {
    return (
        <>
            <section id="aboutus" className="text-gray-400 bg-stone-900 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-0 items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white" data-aos="fade-up">{String.AboutUs.heading}</h1>
                        <p className="mb-8 leading-relaxed" data-aos="fade-up">{String.AboutUs.span}</p>
                        {/* <div className="flex justify-center" data-aos="fade-up">
                            <button className="inline-flex text-white bg-[#97B738] border-0 py-2 px-6 focus:outline-none hover:bg-[#5F7912] rounded text-lg">Button</button>
                            <button className="ml-4 inline-flex text-gray-400 bg-stone-800 border-0 py-2 px-6 focus:outline-none hover:bg-stone-700 hover:text-white rounded text-lg">Button</button>
                        </div> */}
                    </div>
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img className="object-cover object-center rounded" alt="hero" src={Image03}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs