import React from 'react'
import String from '../../../locales/pl-PL/translations'
import Image01 from '../../../assets/images/image03.png'
const WorkWithUs = () => {
    return (
        <>
            <section className="text-gray-400 bg-stone-900 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img className="object-cover object-center rounded" alt="hero" src={Image01}/>
                    </div>
                    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-0 items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white" data-aos="fade-up">{String.WorkWithUs.heading}</h1>
                        <p className=" leading-relaxed" data-aos="fade-up">Nasz zespół współpracuje z inwestorami pasywnymi. Nie wiesz jak uchronić swoje oszczędność przed
                            galopującą inflacją? Chcesz zarobić bez angażowania swojej energii w cały proces <span className='font-bold text-[#97B738]'>inwestowania</span>?
                            Skontaktuj się z nami a przedstawimy Ci wachlarz rzeczowych rozwiązań. Gwarantujemy regularny
                            zysk!</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WorkWithUs