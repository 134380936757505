const String = {

    Contact: {
        KlaudiaMusial: {
            phone: "534 529 383",
            email: "kontakt1@smartinvest.pl"
        },
        NorbertMusial: {
            phone: "572 996 388",
            email: "kontakt2@smartinvest.pl"
        },
        Company: {
            email: "smart@invest-krakow.com"
        }
    },
    Navigation: {
        aboutUs: "O nas",
        invest: "Inwestuj",
        offert: "Oferta",
        contact: "Kontakt",
    },
    Hero: {
        heading: "Zaufaj Ekspertom - Znajdujemy, Remontujemy, Zyskujesz!",
        span: "Twoje oszczędności mogą pracować dla Ciebie, nawet gdy śpisz. Jesteśmy tutaj, by pokazać Ci drogę do pasywnego dochodu i stabilności finansowej. Odkryj świat inwestycji, gdzie jakość spotyka się z lojalnością, a Twoje marzenia o bezpiecznej przyszłości stają się rzeczywistością.",
    },
    AboutUs: {
        heading: "Kim jesteśmy?",
        span: "Jesteśmy zespołem pasjonatów, którzy zajmują się wyszukiwaniem i pozyskiwaniem okazji inwestycyjnych na krakowskim rynku nieruchomości. Naszym celem jest sprzedaż inwestycji z okazałym zyskiem po uprzednim nadaniu jej wartości poprzez remont lub wykończenie. Z pomocą naszego wyspecjalizowanego zespołu całym procesem zajmujemy się od A do Z dbając o najmniejszy detal. Na pierwszym miejscu stawiamy jakość i terminowość a także relację z klientem."
    },
    Cta: {
        ctaSpanFirst: "Jako SMART INVEST oferujemy swoim klientom gotowe projekty inwestycyjne w najwyższej jakości. Dbamy o to aby standard naszego wykończenia spełniał oczekiwania klientów."
    },
    WorkWithUs: {
        heading: "Zainwestuj z nami w nieruchomości",
        span: "Nasz zespół współpracuje z inwestorami pasywnymi. Nie wiesz jak uchronić swoje oszczędność przed galopującą inflacją? Chcesz zarobić bez angażowania swojej energii w cały proces <span className=\"font-bold text-[#97B738]\">inwestowania</span>? Skontaktuj się z nami a przedstawimy Ci wachlarz rzeczowych rozwiązań. Gwarantujemy regularny zysk!"
    }

}

export default String;