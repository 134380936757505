import React from 'react'
import String from '../../../locales/pl-PL/translations'
import { HashLink } from 'react-router-hash-link'

const CtaSimple = () => {
    return (
        <>
            <section id="invest" className="bg-stone-800 text-gray-400 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
                        <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-white">{String.Cta.ctaSpanFirst}</h1>
                        <button className="flex-shrink-0 text-white bg-[#97B738] border-0 py-2 px-8 focus:outline-none hover:bg-[#5F7912] rounded text-lg mt-10 sm:mt-0">
                            <HashLink smooth to={"#contact"}>
                                Umów się
                            </HashLink>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CtaSimple