import React from 'react'
import Config from '../../../Config'
import { Link } from 'react-router-dom'

const CtaSimple = () => {
    return (
        <div id="realization">
            <section className="text-gray-600 body-font border-b">
                <div className="container px-5 py-24 mx-auto flex flex-wrap">
                    <h2 className="sm:text-3xl text-2xl text-gray-900 font-medium title-font mb-2 md:w-2/5">Projekty realizowane przez <br /><span className='logo'>SMART <span className='text-[#3c6ba8]'>BUILD</span></span></h2>
                    <div className="md:w-3/5 md:pl-6">
                        <p className="leading-relaxed text-base" data-aos="fade-up" data-aos-offset="250">Doświadczenie oraz jakość wykonanych prac, jesteś w stanie zoabczyć na naszych mediach społecznościowych <Link to={Config.socialMedia.facebook} target="_blank" className='text-[#3c6ba8] '>Facebook</Link> oraz <Link to={Config.socialMedia.instagram} className='text-[#3c6ba8]' target='_blank'>Instagram</Link> Zapraszamy do odwiedzenia ich w celu zapoznania się z naszymi aktualnie prowadzonymi realizacjami.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CtaSimple