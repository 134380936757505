import React from 'react'

const Hero = () => {
    return (
        <>
            <section className="hero-image-background-smartbuild text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                        <div className="text-center lg:w-2/3 w-full">
                            <h1 className="title-font sm:text-5xl text-3xl mb-4 font-medium text-white" style={{
                                textShadow: "0 0 1px #000"
                            }}>TWORZYMY TWOJĄ PRZESTRZEŃ</h1>
                            <p className="mb-8 text-lg leading-relaxed text-white" style={{
                                textShadow: "0 0 1px #000"
                            }}>Zrealizuj swoje marzenia dzięki nam! Jesteśmy specjalistami w remontach, wykończeniach i kompleksowych projektach pod klucz. Nasza firma tworzy przestrzenie, które odzwierciedlają Twoje pragnienia.</p>
                            {/* <div className="flex justify-center">
                                <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
                                <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
                            </div> */}
                        </div>
                </div>
            </section>
        </>
    )
}

export default Hero