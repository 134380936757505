import React from 'react'
import Config from '../../../Config.js'
import String from '../../../locales/pl-PL/translations.jsx'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import GMSGreen from '../../../assets/images/GrupaMusialSmart-Green.png'

const Footer = () => {
    return (
        <>
            <footer id="contact" className="text-gray-400 bg-stone-900 body-font">
                <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <HashLink smooth to={"#"} className="flex title-font font-medium items-center md:justify-start justify-center text-white">
                            <div className="w-10 h-10 text-white p-2 bg-[#97B738] rounded-full" viewBox="0 0 24 24">
                                <svg width="24" height="24" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 106.956V4H55.2315V106.956" stroke="white" strokeWidth="7" />
                                    <path d="M4 54.2463H55.2315" stroke="white" strokeWidth="7" />
                                    <path d="M4 28.6306H55.2315" stroke="white" strokeWidth="7" />
                                    <path d="M18.7783 106.463V79.3694H39.9606V106.463" stroke="white" strokeWidth="7" />
                                    <path d="M61.6355 56.2167L77.8916 38.9753L104 63.6059V107.448" stroke="white" strokeWidth="7" />
                                    <path d="M69.0246 107.448V79.3694H89.7143V107.448" stroke="white" strokeWidth="7" />
                                </svg>
                            </div>
                            <span className="ml-3 text-xl logo">SMART <span className='text-[#97B738]'>INVEST</span></span>
                        </HashLink>
                        <p className="mt-2 text-sm text-gray-500">Inwestycje w nieruchomości</p>
                    </div>
                    <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">SMART INVEST</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link className="text-gray-400 hover:text-white">SMART INVEST MUSIAŁ Sp. z o.o.</Link>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">KONTAKT</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link className="text-gray-400 hover:text-white">Tel: {String.Contact.NorbertMusial.phone}</Link>
                                </li>
                                <li>
                                    <Link className="text-gray-400 hover:text-white">Email: {String.Contact.Company.email}</Link>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">JESTEŚMY NA</h2>
                            <nav className="list-none mb-10">
                                <li><Link to={Config.socialMedia.facebook} className="text-gray-400 hover:text-white">Facebook</Link></li>
                                <li><Link to={Config.socialMedia.instagram} className="text-gray-400 hover:text-white">Instagram</Link></li>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className='container mx-auto p-5 flex justify-end'>
                    <img src={GMSGreen} alt="Grupa Smart Musiał" className='w-28'/>
                </div>
                <div className="bg-stone-800 bg-opacity-75">
                    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                        <p className="text-gray-400 text-sm text-center sm:text-left">© 2023 Smart Invest —
                            <Link to={"#"} rel="noopener noreferrer" className="text-gray-500 ml-1">Smart-Krakow.pl</Link>
                        </p>
                        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                            <Link to={Config.socialMedia.facebook} className="text-gray-400">
                                <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                                </svg>
                            </Link>
                            <Link to={Config.socialMedia.instagram} className="ml-3 text-gray-400">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                </svg>
                            </Link>
                        </span>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer