import React from 'react'
import { HashLink } from 'react-router-hash-link'

const Navigation = () => {

    return (
        <>
            <header className="backdrop-blur-md bg-white/[0.75] text-gray-600 body-font border-b sticky top-0 z-40">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <HashLink smooth to={"#"} className="flex title-font font-medium items-center text-gray-900 md:mb-0">
                        <div className="w-15 h-15 text-white p-2 bg-[#3c6ba8] rounded-full" viewBox="0 0 32 32">
                            <svg width="32" height="32" viewBox="0 0 179 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="3.53553" y="90.2843" width="35" height="35" transform="rotate(-45 3.53553 90.2843)" stroke="white" strokeWidth="5" />
                                <rect x="34.5355" y="59.2843" width="35" height="35" transform="rotate(-45 34.5355 59.2843)" stroke="white" strokeWidth="5" />
                                <rect x="64.5355" y="89.2843" width="35" height="35" transform="rotate(-45 64.5355 89.2843)" stroke="white" strokeWidth="5" />
                                <rect x="94.5355" y="59.2843" width="35" height="35" transform="rotate(-45 94.5355 59.2843)" stroke="white" strokeWidth="5" />
                                <rect x="125.536" y="28.2843" width="35" height="35" transform="rotate(-45 125.536 28.2843)" stroke="white" strokeWidth="5" />
                            </svg>
                        </div>
                        <span className="ml-3 text-xl lg:text-4xl logo">SMART <span className='text-[#3c6ba8]'>BUILD</span></span>
                    </HashLink>
                    <nav className="hidden md:block md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
                        <HashLink smooth to={"#aboutUs"} className="mr-5 hover:text-gray-900">O firmie</HashLink>
                        <HashLink smooth to={"#offert"} className="mr-5 hover:text-gray-900">Oferta</HashLink>
                        <HashLink smooth to={"#realization"} className="mr-5 hover:text-gray-900">Realizacje</HashLink>
                        <HashLink smooth to={"#partners"} className="mr-5 hover:text-gray-900">Partnerzy</HashLink>
                    </nav>
                    <HashLink smooth to={"#contact"}>
                        <button className="hidden md:inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Kontakt
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </button>
                    </HashLink>
                </div>
            </header>
        </>
    )
}

export default Navigation