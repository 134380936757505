import React from 'react'
import { LiaLockSolid, LiaChartBarSolid, LiaMoneyBillWaveSolid, LiaUniversitySolid } from "react-icons/lia"
const Offert = () => {
  return (
    <>
      <section id="offert" className="text-gray-400 bg-stone-900 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-xs tracking-widest font-medium title-font mb-1 logo">SMART <span className='text-[#97B738]'>INVEST</span></h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white">Co oferujemy</h1>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="p-4 w-full md:w-1/2 lg:w-1/4" data-aos="fade-up" data-aos-offset="250">
              <div className="flex rounded-lg h-full bg-stone-800 bg-opacity-60 hover:bg-opacity-90 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-[#97B738] text-white flex-shrink-0">
                    <LiaMoneyBillWaveSolid />
                  </div>
                  <h2 className="text-white text-lg title-font font-medium">Kapitał</h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">Pomnażanie kapitału bez Twojego wysiłku i angażowania</p>

                </div>
              </div>
            </div>
            <div className="p-4 w-full md:w-1/2 lg:w-1/4" data-aos="fade-up" data-aos-offset="250">
              <div className="flex rounded-lg h-full bg-stone-800 bg-opacity-60 hover:bg-opacity-90 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-[#97B738] text-white flex-shrink-0">
                    <LiaLockSolid />
                  </div>
                  <h2 className="text-white text-lg title-font font-medium">Bezpieczeństwo</h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">Zabezpieczenie beneficjenta - (otrzymujesz prawo własnościowe do lokalu, umowę współpracy sporządzoną notarialnie)</p>

                </div>
              </div>
            </div>
            <div className="p-4 w-full md:w-1/2 lg:w-1/4" data-aos="fade-up" data-aos-offset="250">
              <div className="flex rounded-lg h-full bg-stone-800 bg-opacity-60 hover:bg-opacity-90 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-[#97B738] text-white flex-shrink-0">
                    <LiaChartBarSolid />
                  </div>
                  <h2 className="text-white text-lg title-font font-medium">Zysk</h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">Naszym celem jest dostarczanie gwarantowanych zysków na poziomie nawet od <span className='font-bold text-[#97B738]'>12%</span> do <span className='font-bold text-[#97B738]'>18%</span>. Dzięki naszej ekspertyzie i kreatywności potrafimy widzieć potencjał tam, gdzie inni widzą wyłącznie nieruchomość.</p>
                </div>
              </div>
            </div>
            <div className="p-4 w-full md:w-1/2 lg:w-1/4" data-aos="fade-up" data-aos-offset="250">
              <div className="flex rounded-lg h-full bg-stone-800 bg-opacity-60 hover:bg-opacity-90 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-[#97B738] text-white flex-shrink-0">
                    <LiaUniversitySolid />
                  </div>
                  <h2 className="text-white text-lg title-font font-medium">Inwestycja</h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">Inwestowanie dowolnej sumy. <span className='font-bold text-[#97B738]'>Bez obaw.</span> Masz dowolność w wyborze kwoty jaką chciałbyś zainwestować. Niski próg wejścia we współpracę z nami gwarantuje Ci <span className='font-bold text-[#97B738]'>maksymalne</span> zabezpieczenie
                    nawet niewielkich oszczędności.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Offert