const Config = {

    socialMedia: {
        facebook: "https://www.facebook.com/smartinvestmusial",
        instagram: "https://www.instagram.com/smartbuild_krakow/",
        twitter: "",
    }

}

export default Config