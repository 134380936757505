import React, { useEffect } from 'react'
import Navigation from './components/Navigation'
import Hero from './components/Hero'
import AboutUs from './components/AboutUs'
import Offert from './components/Offert'
import CtaSimple from './components/CtaSimple'
import Partners from './components/Partners'
import Footer from './components/Footer'
// import PopUp from './components/PopUp'
import AOS from "aos";
import "aos/dist/aos.css";
import './style/Hero.css'

const SmartBuild = () => {

  useEffect(() => {
    document.title = "Smart Build - Wykończenia i adaptacje wnętrz."
    AOS.init();
    AOS.refresh();

  }, []);


  return (
    <>
      <Navigation />
      <Hero />
      <AboutUs />
      <Offert />
      <CtaSimple />
      <Partners />
      <Footer />
      {/* <PopUp /> */}
    </>
  )
}

export default SmartBuild